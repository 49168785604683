body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-[#334155];
  } 
}

@layer components {
  .wrapper {
    @apply mx-auto max-w-[1440px] px-4;
  }
}


@font-face {
  font-family: 'SofiaPro';
  font-weight: 900;
  src: local('SofiaPro'), url(./assets/fonts/sofiapro-black.woff) format('woff');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 700;
  src: local('SofiaPro'), url(./assets/fonts/sofiapro-bold.woff) format('woff');
}
@font-face {
  font-family: 'SofiaPro';
  font-weight: 600;
  src: local('SofiaPro'), url(./assets/fonts/sofiapro-semi-bold.woff) format('woff');
}
@font-face {
  font-family: 'SofiaPro';
  font-weight: 500;
  src: local('SofiaPro'), url(./assets/fonts/sofiapro-medium.woff) format('woff');
}
@font-face {
  font-family: 'SofiaPro';
  font-weight: 400;
  src: local('SofiaPro'), url(./assets/fonts/sofiapro-regular.woff) format('woff');
}
@font-face {
  font-family: 'SofiaPro';
  font-weight: 300;
  src: local('SofiaPro'), url(./assets/fonts/sofiapro-light.woff) format('woff');
}